.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.custom-header {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Distribute space between logo and menu */
}

.logo-container {
  flex-shrink: 0; /* Prevent logo from shrinking */
  margin-top: 20px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.logo {
  width: 30%;
  max-width: 400px;
  height: auto;
  object-fit: contain; /* Responsive image */
}

.about-card {
  background-color: #282936;
  height: 270px;
  width: 40%;
  max-width: 400px;
  border-radius: 5px;
}

.about-title {
  margin: 0 0 10px;
  font-size: 24px;
  color: white;
  text-align: center;
  padding: 10px;
}

.about-text {
  max-width: 500px;
  margin: 20px auto; /* Add vertical margin */
  margin-left: 20px; /* Add left margin */
  color: white;
  padding-right: 10px; /* Corrected paddingRight syntax */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .container {
      flex-direction: column; /* Change to column layout */
      align-items: center; /* Center items */
  }

  .logo {
      width: 80%; /* Adjust logo width for mobile */
  }

  .about-card {
      width: 80%; /* Adjust card width for mobile */
  }
}

.menu-container {
  flex-grow: 1;
  display: flex;
  justify-content: center; 
}

.menu {
  display: flex; 
  justify-content: center; 
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .menu {
      flex-direction: column; 
      align-items: center;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */
/* App.css */
.custom-header {
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white; /* White background */
 
}

.menu {
  display: flex;
  justify-content: center; /* Center the menu items */
  line-height: 64px; /* Align text vertically in the header */
}

.menu .ant-menu-item {
  color: black; /* Black text color */
}

/* .menu .ant-menu-item:hover {
  color: #1890ff; /* Optional: change color on hover */


.ant-layout-content {
  margin-top: 64px; /* Adjust based on the header height */
}

@media (max-width: 768px) {
  div {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      align-items: center; /* Center align the items */
  }
  
  span {
      margin-right: 0; /* Remove right margin in column layout */
      text-align: center; /* Center text on smaller screens */
  }
}


    @media (max-width: 667px) {
        div[style*="margin-left: 150px"] {
            margin-left: 0 !important;
        }
    }

    .image-container {
      max-width: 100%;
      overflow: hidden;
      height: 500px;
    }
    
    .main-p-img1 {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    
    }
    
    .heading-container {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 20px;
    }
    
    .main-p-head {
      max-width: 700px;
      line-height: 1.5;
      margin: 0;
      font-size: 24px;
      font-weight: bold;
       color: "#C2274F"
    }
    
    .home-p-section3 {
      background-color: #FFF0EC;
      padding: 20px;

      /* max-height: 300px;
      position: relative; */
    
    }
    
    .section3-container1 {
      display: flex;
      flex-direction: row;
      max-width: 80%;
      margin: auto;
      position: relative;
    }
    
    .section3-container {
      display: flex;
      flex-direction: row;
      max-width: 80%;
      margin: auto;
      position: relative;
      background-color: #FFF0EC;
    }
    
  
    
    .section3-image-container {
      flex: 1;
      max-width: 70%;
      height: 100%;
    }
    
    .section7-image-container {
      flex: 1;
      max-width: 100%;
      height: 100%;
      background-color: #66371b;
      position: relative;
      /* display: inline-block; */
    }
    
    .section7-responsive-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
      border-radius: 15px;
      max-height: 200px;
      height: auto;
    }
    
    .section3-responsive-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
      border-radius: 15px;
      max-height: 400px;
    }
    @media (max-width: 780px) {
      .responsive-image {
        height: 500px; /* Increase height for smaller screens */
      }
    }
    
    
    .section3-text-box {
      position: relative;
      /* top: 5%; */
      right: 16%;
      width: 50%;
      max-width: 400px;
      background-color: rgb(255, 255, 255);
      color: rgb(0, 0, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      /* border-radius: 20%; */
      /* margin-right:60px; */
    }
    
    .btn {
    
      display: inline-block;
      padding: 12px 20px;
      font-size: 12px;
      color: white;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s;
    }
    .packagebtn {
    
      display: inline-block;
      padding: 8px 20px;
      font-size: 12px;
      color: white;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      /* height: ; */
      transition: background-color 0.3s ease, transform 0.2s;
    }
    
    .btn:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }
    
    .btn:active {
      background-color: #004494;
      transform: translateY(1px);
    }
    
    .btn.disabled {
      background-color: #ccc;
      cursor: not-allowed;
      pointer-events: none;
    }
    
    .RC-div {
      width: 100%;
      display: flex;
      /* flex-direction: row; */
      justify-content: space-around;
    }
    
    .home-p-sections {
      display: flex;
      margin: auto;
    }
    
    .RC-img-div {
      flex: 1;
      max-width: 100%;
      margin-top: -5px;
    }
    
    .RC-txt-div {
      flex: 1;
      max-width: 100%;
      /* padding: 20px; */
      /* margin: auto; */
    }
    
    .RC-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 400px;
    }
    
    .tab-div {
      display: flex;
      justify-content: center;
      /* background-color: rgb(207, 231, 161); */
      min-height: 250px;
      max-height: 350px;
      /* border-left: #fff; */
    }
    
    /* .tab-div1 {
    
      border-left: #fff;
    } */
    
    
    @media (max-width: 600px) {
      .section3-text-box {
        margin-left: 300px !important;
      }
    }

@media (min-width: 481px) {


  .section3-text-box {
    font-size: 14px;
    width: 90%;
    /* margin-left: 300px; */
    /* max-width: 200px; */
  }

  .section3-responsive-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 15px;
    height: 500px;
  }

  .RC-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    height: 500px;
  }

}

@media (max-width: 800px) {
  .section3-responsive-image {
    display: none;
  }

  .overlay-text {
    font-size: 8px;
    /* Adjust font size for larger screens */
  }
}

@media (max-width: 600px) {

  .overlay-text {
    font-size: 12px !important;
    /* Adjust font size for larger screens */
    /* display: none; */
  }
}
@media (max-width: 480px) {
  .section3-container {
    display: flex; /* Use flex to center content */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    position: relative; /* Maintain relative positioning for child elements */
    padding: 20px; /* Add padding for breathing room */
  }

  .section3-image-container {
    display: none; /* Hide the image on small screens */
  }

  .section3-text-box {
    width: 90%; /* Maintain the desired width */
    max-width: 700px;
    background-color: rgba(255, 255, 255, 0.8); /* Background for readability */
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0; /* Add vertical margin to prevent overlap */
    text-align: center; /* Center text inside the box */
  }
}

.home-p-section3 {
  position: relative; /* Ensure the section is relative for absolute children */
  padding: 40px 0; /* Add some padding to the top and bottom */
}

.section3-text-box {
  margin-top: 10px; /* Ensure there's spacing */
}

/*  */

/* Media Queries for Responsiveness for image*/

@media (max-width: 768px) {

  .RC-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 300px;
  }

  .overlay-text {
    font-size: 18px;
    /* Adjust font size for larger screens */
  }

  .RC-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo {
    font-size: 20px;
  }

  .main-p-heading {
    font-size: 17px;
  }

  .main-p-text {
    font-size: 18px;
  }

  .main-p-text1 {
    font-size: 18px;
  }

  .ant-menu-horizontal {
    font-size: 14px;
  }

  .main-p-head {
    font-size: 18px;

  }
}

@media (max-width: 500px) {
  .main-p-text {
    font-size: 15px;
  }

  .main-p-text1 {
    font-size: 15px;
  }

  .main-p-heading {
    font-size: 12px;
  }

  .main-p-head {
    font-size: 14px;

  }
}
/* Show table on large screens */
.table-layout {
  display: block;
}

/* Hide table and show card layout on small screens */
.card-layout {
  display: none;
  
}

/* Show card layout on screens smaller than 900px */
@media (max-width: 900px) {
  .table-layout {
    display: none;
  }
  .card-layout {
    display: block;
    color: #282936;
  }
}
@media (max-width: 760px) {
  .table-layout {
    display: none;
  }
  .card-layout {
    display: block;
    /* color: #282936; */
    /* margin-left: 340px; */
  }
  .card-cus{
    margin-left: 340px;
  }
}
.mobile-page{
  display: none;
}
@media (max-width: 560px) {
  .web-page {
    display: none;
  }
  .mobile-page {
    display: block;
    /* color: #282936; */
    /* margin-left: 340px; */
  }
 
}